

@media screen and (min-width: 900px) {
    .nftMintHouseImage {
        height:3vh;
        object-fit: contain;
    }
    .nftMintPageImage {
        height:2vh;
    }
    .nftMintPage {
        position: absolute;
        display: flex;
        width: 100%;
        opacity: 0;
        align-items: center;
        justify-content: center;
        margin: auto;
        top: 11vh;
        z-index: 6;
    }
}

@media screen and (max-width: 900px) {
    .nftMintHouseImage {
        width: 300px;
    }

    .nftMintPage {
        position: absolute;
        display: flex;
        width: 100%;
        opacity: 0;
        align-items: center;
        justify-content: center;
        margin: auto;
        top: calc(7vh + 30px);
        z-index: 6;
    }

    .nftMintPageImage {
        width: 90px;
    }
}