.blur {
    filter:blur(3px) brightness(85%);
}


.centerImage {
    grid-area: centerimage;
    z-index: 1;
    justify-self: center;
}

.backImages {
    grid-area: line2;
}

.backWords {
    grid-area: line1;
}

.frontImages {
    grid-area: line3;
    z-index: 2;
}

.frontWords {
    grid-area: line4;
    z-index: 3;
}



@media screen and (min-width: 900px) {
    .topImage {
        display: grid;
        height: 100%;
        width: 100%;
        grid-template: 
        ".....  .....  ....." 2%
        "line1  line1  line1" 13%
        ".....  centerimage ....." 15%
        "line2  line2  line2" 5%
        ".....  .....  ....." 20%
        "line3  line3  line3" 35%
        "line4  line4  line4" 10%
        / 25%   50%     25%
    }

  }
  
@media screen and (max-width: 900px) {

    @media screen and (max-height: 701px) {
        .topImage {
            display: grid;
            height: 100%;
            width: 100%;
            grid-template: 
            ".....  .....  ....." 2.5%
            "line1  line1  line1" 5%
            ".....  centerimage ....." 10%
            ".....  .....  ....." 20%
            "line2  line2  line2" 5%
            ".....  .....  ....." 10%
            "line3  line3  line3" 35%
            "line4  line4  line4" 17.5%
            / 0%   75%     25%
        }
    }

    @media screen and (min-height: 701px) {
        .topImage {
            display: grid;
            height: 100%;
            width: 100%;
            grid-template: 
            ".....  .....  ....." 2.5%
            "line1  line1  line1" 5%
            ".....  centerimage ....." 10%
            ".....  .....  ....." 20%
            "line2  line2  line2" 5%
            ".....  .....  ....." 25%
            "line3  line3  line3" 25%
            "line4  line4  line4" 10%
            / 2.5%   95%     2.5%
        }
    }
  }