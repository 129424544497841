.explaination {
  background-color: rgba(255, 255, 255, 0.68);
  width: 90%;
  max-width: 800px;
  /* margin-top: 20px; */
  margin-inline: auto;
}

.explaination-bottom {
  padding: 42px 10px 42px 10px;
  padding-top: 50px;
}

.explaination-mobile {
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.68);
  margin: 50px 10px 50px 10px;
  width: 80vw;
}

.explaination-bottom {
  border: 1px solid #6f6dcf;
  background-color: rgba(255, 255, 255, 0.68);
  padding: 6vh 6vw 6vh 6vw;
  margin: 6vw;
  margin: -1vh 2vw 6vh 2vw;
  padding-top: 6vh;
  font-size: 16px;
  text-align: left;
  z-index: 1;
}

.explaination-color {
  color: #6f6dcf;
  font-weight: bold;
}

.note-title {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  color: #6f6dcf;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
}

.title-content {
  background-color: white;
  padding-left: 1vh;
  padding-right: 1vh;
  position: absolute;

}

/* .sns-icons {
  margin-bottom: 4vh;
} */

.sns {
  display: grid;
  height: 30px;
  grid-template:
    "sns-twitter  sns-discord" 100%
    /50% 50%;
}
.sns-twitter {
  grid-area: sns-twitter;
  height: 4vh;
  align-self: center;
  justify-self: right;
  margin-right: 12px;
}

.sns-discord {
  grid-area: sns-discord;
  height: 4vh;
  align-self: center;
  justify-self: left;
  margin-left: 12px;
}

.twitter-image {
  grid-area: twitter;
  width: 24px;
  margin: 4px;
}

.discord-image {
  grid-area: discord;
  width: 24px;
  margin: 4px;
}
