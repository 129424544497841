@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");

.contents {
  grid-area: contents;
}

.App-footer {
  background-color: #fffbe7;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

@media (min-width: 900px) {
  .contents-wrapper {
    background-color: rgba(255, 255, 255, 0.68);
    /* display: grid;
    grid-template:
      "..... contents ....." auto
      / 25% 50% 25%; */
  }
}

@media (max-width: 900px) {
  .contents-wrapper {
    background-color: rgba(255, 255, 255, 0.68);
    display: grid;
    grid-template:
      "..... contents ....." auto
      / 2% 96% 2%;
  }
}

@media screen and (max-width: 900px) {
  .App-header {
    background-color: #fffbe7;
    height: 60vh;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .display-init {
    width: 100%;
  }

  .explanation-container {
    width: 100%;
  }

  .left {
    grid-area: left1;
    background-color: rgba(255, 255, 255, 0.68);
    top: -50px;
  }

  .right {
    grid-area: right1;
    background-color: rgba(255, 255, 255, 0.68);
    top: -50px;
  }
}

@media screen and (min-width: 900px) {
  .App-header {
    background-color: #fffbe7;
    height: 60vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .display-init {
    background-color: rgba(255, 255, 255, 0.68);
  }

  .left {
    grid-area: left1;
    background-color: rgba(255, 255, 255, 0.68);
    top: -50px;
  }

  .right {
    grid-area: right1;
    background-color: rgba(255, 255, 255, 0.68);
    top: -50px;
  }
}

html {
  width: "100vw";
  height: "100vh";
  overflow-x: hidden;
  -webkit-appearance: none;
}

body {
  width: "100vw";
  height: "100vh";
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.48);
  -webkit-appearance: none;
}
