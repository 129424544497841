.terms-of-service {
    background-color: #FFFEF8;
    font-family: 'Zen Kaku Gothic Antique';
    display: grid;
    text-align: center; 
    align-items: center;
    justify-content: center;
    grid-template:
    "header" 6vh
    "nft-idol-house" 5vh
    "nft-mint-page" 20px
    "title" 15vh
    "content" 1fr/
    100%
}

.header {
    grid: header;
}

.nft-idol-house {
    grid: nft-idol-house;
    margin: auto;
}

.nft-mint-page {
    grid: nft-mint-page;
    margin: auto;
}

.nftMintPageImage {
    height: 2vh;
    object-fit: contain;
}

.title {
    grid: title;
    font-weight: bold;
    color: #6F6DCF;
    font-size: 5vh;
}

.content {
    grid: content;
    display: grid;
    align-self: start;
    grid-template:
    "content-left content-center content-right" 100% /
    10%  80%  10%
}

.content-center {
    grid: content-center;
    font-weight: bold;
    text-align: left;
    font-size: 2vh;
}

.App-footer {
    background-color: #FFFBE7;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

