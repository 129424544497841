p {
  margin: 0;
}

.display-init {
  min-height: 250px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sale-status-text {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #252525;
  margin-top: 12px;
  margin-bottom: 24px;
}

.title-text {
  font-size: 36px;
  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
  font-weight: 700;
  text-align: center;
  color: #6f6dcf;
  margin-top: 0px;
  margin-bottom: 36px;
}

.thid {
  margin: 10px;
}

.reveal-button {
  width: 208px;
  height: 56px;

  background: #fffbe7;
  border: 1px solid #84720d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  font-style: normal;
  font-weight: 300;
  font-size: 24px;

  color: #84720d;
  cursor: pointer;
}

.link-selection-card {
  width: 600px;
  @media screen and (max-width: 900px) {
    width: 300px;
  }
  margin: 72px auto 24px auto;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4efd7;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
}

.link-selection-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #6f6dcf;
  margin-bottom: 12px;
}

.link-selection-message {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #675917;
}

.link-selection-items {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #6f6dcf;
  text-align: left;
  padding-inline-start: 0px;
  line-height: 32px;
}

.thirdweb-frame {
  margin-top: 48px;
  height: 600px;
  border: 2px;
  border-style: inset;
  border-color: #000000;
  @media screen and (max-width: 900px) {
    width: 300px;
  }
}

.loading-frame {
  margin-top: 48px;
  height: 600px;
  border: 2px;
  border-style: inset;
  border-color: #000000;
  width: 600px;
  @media screen and (max-width: 900px) {
    width: 300px;
  }
}

.iframe-wrapper {
  pointer-events: none;
}

.iframe-link {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 600px;
  height: 600px;
  z-index: 9999;
}
li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
